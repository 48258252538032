<template>
  <div class="registration">
    <form ref="authForm" class="login-form" @submit.prevent="onSubmitForm">
      <h1 class="auth-title">Sign up</h1>
      <p class="auth-subtext">
        Enter your e-mail, password and confirm your password to create an
        account
      </p>

      <AppField
        label="Enter your name"
        required
        :isInvalid="$v.name.$error"
        @input="setField($event, 'name')"
      >
        <template v-slot:error>
          <p v-if="!$v.name.required">This field is required</p>
        </template>
      </AppField>

      <AppField
        label="Enter your e-mail"
        required
        :isInvalid="$v.email.$error"
        @input="setField($event, 'email')"
      >
        <template v-slot:error>
          <p v-if="!$v.email.required">This field is required</p>
          <p v-if="!$v.email.email">Incorrect email format</p>
        </template>
      </AppField>

      <AppField
        type="password"
        label="Password"
        required
        :isInvalid="$v.password.$error"
        @input="setField($event, 'password')"
      >
        <template v-slot:error>
          <p v-if="!$v.password.required">This field is required</p>
          <p v-if="!$v.password.minLength">
            Password must have at least
            {{ $v.password.$params.minLength.min }} symbols.
          </p>
        </template>
      </AppField>

      <AppButton
        title="Create Profile"
        class="form-btn"
        className="primary"
        type="submit"
        :pending="pending"
      />
    </form>

    <p class="auth-form-subtext">Easy login with your social profile</p>

    <SocialAuthButtons />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { email, minLength, required, sameAs } from "vuelidate/lib/validators";
import { ROUT_PATH, USER_ACCOUNT_NAMES } from "@/helpers/rout-constants";
import CompletedTemplate from "@/helpers/completed-template-hash";
import { authMixin } from "@/mixins/AuthMixin";
import SocialAuthButtons from "@/components/auth/SocialAuthButtons";
import { mixpanelMixin } from "@/mixins/MixPanelMixin";
import * as FullStory from "@fullstory/browser";

export default {
  name: "Registration",
  mixins: [authMixin, mixpanelMixin],
  components: {
    SocialAuthButtons,
  },

  computed: {
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn",
      isSubscribed: "user/isSubscribed",
    }),
  },

  data() {
    return {
      name: "",
      email: "",
      password: "",
      doc_hash: "",
      doc_type: "",
    };
  },

  validations: {
    name: {
      required,
    },
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(8),
    },
  },

  async created() {
    if (this.isLoggedIn) {
      if (CompletedTemplate.get() || this.$route.params.planId) {
        await this.$router.push(ROUT_PATH.SUBSCRIBE_STEPS.SUBSCRIBE_PLANS);
      } else {
        await this.$router.push(ROUT_PATH.USER_DASHBOARD);
      }
    }

    this.mixpanelTrack("V2-REGISTRATION");

    let template = CompletedTemplate.get();
    if (
      template != null &&
      template.hash != null &&
      template.hash != undefined
    ) {
      this.doc_hash = template.hash;
    }
    if (
      template != null &&
      template.type != null &&
      template.type != undefined
    ) {
      this.doc_type = template.type;
    }
  },

  methods: {
    async onSubmitForm() {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      let name = this.name.split(" ");
      let first_name = "";
      let last_name = "";

      for (let i = 0; i < name.length; i++) {
        if (name.length == i + 1) {
          last_name = name[i];
        } else {
          if (first_name == "") {
            first_name = name[i];
          } else {
            first_name = first_name + " " + name[i];
          }
        }
      }
      if (name.length == 1) {
        first_name = name[0];
        last_name = "";
      }

      const payload = {
        first_name: first_name,
        last_name: last_name,
        email: this.email,
        password: this.password,
        doc_hash: this.doc_hash,
        doc_type: this.doc_type,
      };

      if (CompletedTemplate.get() || this.$route.params.planId) {
        try {
          await this.sendData(payload, "auth/register");

          if (this.doc_type == "resume") {
            await this.$store.dispatch("resumeCreation/bindResumeToUser");
          }
          if (this.doc_type == "letter") {
            await this.$store.dispatch("coverLetterCreation/bindLetterToUser");
          }

          FullStory.setUserVars({
            email: this.email,
          });

          this.mixpanelTrack("V2-REGISTERED", {
            email: this.email,
          });

          await this.$router.push(ROUT_PATH.SUBSCRIBE_STEPS.SUBSCRIBE_PLANS);
        } catch (e) {
          console.error(e);
        }
      } else {
        try {
          FullStory.setUserVars({
            email: this.email,
          });

          this.mixpanelTrack("V2-REGISTERED", {
            email: this.email,
          });

          await this.sendData(payload, "auth/register");
          await this.$router.push(ROUT_PATH.USER_DASHBOARD);
        } catch (e) {
          console.error(e);
        }
      }
    },
  },
};
</script>

<style scoped lang="scss" src="./index.scss" />
